import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { ExtendedWorklogDayType, ExtendedWorklogType, WorklogDayType, WorklogType } from 'types/WorkLogTypes';
import { generateDateRange, SortWorkLogsByAscDate, SortWorkLogsByDescDate } from 'utils/date';

dayjs.extend(isSameOrBefore);

export const findMissingLogDays = (sortedWorklogs: WorklogDayType[], fromDate: Dayjs | null, toDate: Dayjs | null) => {
  const actualLogDates = sortedWorklogs.map(({ date }) => date);
  const selectedDateRange = generateDateRange(fromDate, toDate);
  const publicHolidayData = localStorage.getItem('public-holiday');
  const holidays = publicHolidayData ? JSON.parse(publicHolidayData) : [];

  const missingLogs = selectedDateRange?.filter(
    (date) =>
      !actualLogDates.includes(date) && dayjs(date).day() !== 0 && dayjs(date).day() !== 6 && !holidays.includes(date),
  );

  return missingLogs;
};

// Regroup the logs by date
const regroupLogsByDate = (logs: WorklogType[]) => {
  const regroupedLogs = logs.reduce((result: ExtendedWorklogDayType[], log: ExtendedWorklogType) => {
    const existingEntryIndex = result.findIndex((entry: ExtendedWorklogDayType) => entry.date === log.date);
    if (existingEntryIndex !== -1) {
      result[existingEntryIndex].worklogs.push(log);
    } else {
      result.push({ date: log.date, startDate: log.startDate, endDate: log.endDate, worklogs: [log] });
    }
    return result;
  }, []);

  return regroupedLogs;
};

const checkDiff = (current: string, next: string) => {
  const currentDate = dayjs(current);
  const nextDate = dayjs(next);
  const diff = Math.abs(nextDate.diff(currentDate, 'day'));

  if (diff === 1) return true;

  if (diff === 3) {
    // Check if the two dates between them are weekends
    const date1 = nextDate.subtract(1, 'day');
    const date2 = nextDate.subtract(2, 'days');
    const areWeekends = date1.day() === 0 && date2.day() === 6; // 6 represents Saturday, 0 represents Sunday
    return areWeekends;
  }

  return false;
};

const isSameConsecutiveLeaveLog = (currentLog: any, nextLog: any) => {
  if (
    currentLog.activity.activity_id === nextLog.activity.activity_id &&
    dayjs(currentLog.start_time).hour() === dayjs(nextLog.start_time).hour() &&
    dayjs(currentLog.end_time).hour() === dayjs(nextLog.end_time).hour() &&
    currentLog.status === nextLog.status &&
    currentLog.description === nextLog.description &&
    checkDiff(currentLog.date, nextLog.date)
  )
    return true;

  return false;
};

export const groupLogs = (logs: WorklogDayType[]) => {
  const groupedLogs = [];
  let currentGroup: any = null;
  const allLogs = [...logs]?.sort(SortWorkLogsByAscDate).flatMap((item: WorklogDayType) => item.worklogs);

  for (let i = 0; i < allLogs.length; i++) {
    const currentLog = allLogs[i];
    const nextLog = allLogs[i + 1];

    if (currentLog.worklog_type === 'Worklog') groupedLogs.push(currentLog);

    if (
      currentLog.worklog_type === 'LeaveLog' &&
      nextLog?.worklog_type === 'LeaveLog' &&
      currentLog.activity.activity_id === nextLog.activity.activity_id &&
      isSameConsecutiveLeaveLog(currentLog, nextLog)
    ) {
      if (!currentGroup) {
        currentGroup = {
          ...currentLog,
          startDate: currentLog.date,
          endDate: nextLog.date,
          groupedIds: [currentLog.slip_id, nextLog.slip_id],
        };
      } else {
        currentGroup = {
          ...currentGroup,
          endDate: nextLog.date,
          groupedIds: [...currentGroup.groupedIds, nextLog.slip_id],
        };
      }
    } else if (currentGroup) {
      groupedLogs.push(currentGroup);
      currentGroup = null;
    } else groupedLogs.push(currentLog);
  }

  return regroupLogsByDate(groupedLogs).sort(SortWorkLogsByDescDate);
};
